.numbers_root {
  position: relative;
  width: 100vw;
  max-width: 1600px;
  margin: auto;
  height: auto;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .numbers_root .left_box,
  .numbers_root .right_box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: auto; }
    .numbers_root .left_box div,
    .numbers_root .right_box div {
      height: 256px;
      width: 256px;
      padding: 16px;
      margin: 1em;
      text-align: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
      .numbers_root .left_box div ion-icon,
      .numbers_root .right_box div ion-icon {
        font-size: 5em;
        width: 100%;
        color: #2d3436;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none; }
      .numbers_root .left_box div p,
      .numbers_root .right_box div p {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        font-weight: 700;
        font-size: 1.5em; }
        .numbers_root .left_box div p span,
        .numbers_root .right_box div p span {
          width: 100%;
          display: block; }

@media (max-width: 768px) {
  .numbers_root .left_box,
  .numbers_root .right_box {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .numbers_root .left_box div,
    .numbers_root .right_box div {
      width: 128px;
      height: auto;
      margin: 1em 1em;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .numbers_root .left_box div ion-icon,
      .numbers_root .right_box div ion-icon {
        font-size: 4em; }
      .numbers_root .left_box div p,
      .numbers_root .right_box div p {
        font-size: 1em;
        line-height: 1em; } }

@supports (-ms-ime-align: auto) {
  /* Edge 16+ CSS */
  ion-icon {
    position: absolute;
    overflow: hidden;
    max-width: 1px;
    max-height: 1px; } }
