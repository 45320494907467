.cl_root td {
  padding: 4px 12px;
  vertical-align: middle !important; }

.cl_root ion-icon {
  font-size: 20px;
  padding: 0px;
  margin: 0px;
  color: white; }

.cl_root .btn-call {
  background: #2ecc71; }

.cl_root .btn-del {
  background: #e74c3c; }

.cl_root .toast_root h1 {
  margin-bottom: 20px; }

.cl_root .toast_root img {
  width: 100%; }

.cl_root .qr-call {
  display: none; }

@media screen and (min-width: 992px) {
  .cl_root .qr-call {
    display: inline; }
  .cl_root .mobile-call {
    display: none; } }
