.checkbox_root {
  position: relative;
  width: 100%;
  height: 100vh;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden; }
  .checkbox_root .checkbox_wrap {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: .5em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    margin: 1em; }
    .checkbox_root .checkbox_wrap div {
      margin: .5em;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .checkbox_root .checkbox_wrap div h3 {
        font-size: 4rem;
        padding: 0 .5em;
        color: #fff;
        text-transform: uppercase;
        display: inline-block;
        text-shadow: 3px 3px 10px #4a5559;
        font-weight: 800; }
      .checkbox_root .checkbox_wrap div div {
        position: relative;
        display: inline-block;
        width: 5em;
        height: 5em;
        background-color: rgba(192, 57, 43, 0.7); }
        .checkbox_root .checkbox_wrap div div ion-icon {
          position: absolute;
          font-size: 5em;
          color: #fff; }

@media (max-width: 768px) {
  .checkbox_root .checkbox_wrap {
    padding: 0;
    margin: 0; }
    .checkbox_root .checkbox_wrap div h3 {
      font-size: 1.5em; }
    .checkbox_root .checkbox_wrap div div {
      width: 2.5em;
      height: 2.5em; }
      .checkbox_root .checkbox_wrap div div ion-icon {
        position: absolute;
        font-size: 2.5em;
        color: #fff; } }
