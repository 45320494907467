.partners_root {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 3em 0; }
  .partners_root .partners_items_go_here {
    min-height: 256px; }
  .partners_root h2 {
    text-transform: uppercase;
    text-align: center;
    padding: 1em;
    color: #a6988d;
    font-size: 3em;
    font-weight: 800; }
  .partners_root .partners_deck {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
    padding: 0 2em; }
    .partners_root .partners_deck .partner_pair {
      width: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .partners_root .partners_deck .partner_pair .partner_card {
        position: relative;
        width: 300px;
        height: 300px;
        margin: 2em .5em;
        background-color: #2d3436;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        overflow: hidden; }
        .partners_root .partners_deck .partner_pair .partner_card div {
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: rgba(192, 57, 43, 0.7);
          color: #fff;
          width: 100%;
          height: auto;
          padding: .5em;
          line-height: 1.2em; }
          .partners_root .partners_deck .partner_pair .partner_card div h3 {
            font-weight: 600;
            margin-bottom: .5em;
            font-size: 1.2em; }
          .partners_root .partners_deck .partner_pair .partner_card div p {
            font-weight: 200; }
      .partners_root .partners_deck .partner_pair #partner_text {
        position: relative;
        width: 300px;
        height: 300px;
        margin-right: .5em;
        background-color: rgba(0, 0, 0, 0);
        border: 0.5em solid #c0392b; }
        .partners_root .partners_deck .partner_pair #partner_text h3 {
          text-transform: uppercase;
          color: #fff;
          font-weight: 800;
          font-size: 2.5em;
          line-height: 1.2em;
          padding: 8px;
          text-shadow: 1px 1px 5px #2d3436; }

#partner_card_1:hover,
#partner_card_2:hover,
#partner_card_3:hover {
  cursor: pointer; }

#partner_card_1:active,
#partner_card_2:active,
#partner_card_3:active {
  -webkit-transform: translateY(5px);
  -ms-transform: translateY(5px);
  transform: translateY(5px); }

@media (max-width: 768px) {
  .partners_root .partners_deck {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    widows: 100%; }
    .partners_root .partners_deck .partner_card {
      width: 100%; }
    .partners_root .partners_deck .partner_pair {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      width: 100%; }
      .partners_root .partners_deck .partner_pair #partner_text,
      .partners_root .partners_deck .partner_pair #partner_card_1,
      .partners_root .partners_deck .partner_pair #partner_card_2,
      .partners_root .partners_deck .partner_pair #partner_card_3 {
        width: 100%;
        -webkit-box-shadow: 3px 3px 10px #000;
        box-shadow: 3px 3px 10px #000; }
      .partners_root .partners_deck .partner_pair #partner_text {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 1em; } }

@media (min-width: 769px) and (max-width: 1025px) {
  .partners_root .partners_deck .partner_pair {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; } }

@media (max-width: 1260px) and (min-width: 768px) {
  .partners_root .partners_deck #partner_card_2,
  .partners_root .partners_deck #partner_card_3,
  .partners_root .partners_deck #partner_card_4 {
    width: 100%; }
  .partners_root .partners_deck .partner_pair {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; } }
