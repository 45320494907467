.smartslider_root {
  margin: 5% auto;
  padding: 0;
  color: #222;
}

.image-gallery-slide .image-gallery-image {
  height: 40vh !important;
  width: 100% !important;
  object-fit: cover !important;
}

.fullscreen .image-gallery-slide-wrapper .image-gallery-swipe .image-gallery-slides .image-gallery-slide div .image-gallery-image {
  height: 100vh !important;
  max-height: none !important;
}

.image-gallery-bullet.active {
  max-width: 10px;
}
