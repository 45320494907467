.quote_root {
  position: relative;
  width: 100%;
  min-height: 40vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden; }
  .quote_root .background-img {
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    bottom: 5%;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed; }
  .quote_root .quote {
    position: relative;
    width: 35%;
    min-width: 356px;
    height: 20vh;
    min-height: 256px;
    background-color: rgba(192, 57, 43, 0.8);
    padding: 1em;
    color: #fff;
    margin: 1em 0 1em 10%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .quote_root .quote ion-icon {
      position: absolute;
      left: 16px;
      top: 16px;
      color: rgba(241, 241, 241, 0.6);
      font-size: 4em; }
    .quote_root .quote p {
      margin: 5% 0 0 10%;
      font-size: 1.5em;
      font-weight: 200; }
      .quote_root .quote p span {
        font-size: .8em;
        text-shadow: none;
        position: absolute;
        bottom: 8px;
        right: 8px; }

@media (max-width: 768px) {
  .quote_root {
    height: 50vh; }
    .quote_root .background-img {
      background-attachment: inherit; }
    .quote_root .quote {
      width: 80%;
      height: auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      min-width: 0; }
      .quote_root .quote p {
        line-height: 1.2em;
        font-size: 1.3em;
        font-weight: 2em; } }

@media (min-width: 769px) and (max-width: 1025px) {
  .quote_root {
    height: 50vh; }
    .quote_root .background-img {
      background-attachment: inherit; }
    .quote_root .quote {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      height: 35vh; } }

.cms_gear {
  font-size: 2em;
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  background-color: rgba(45, 52, 54, 0.8);
  border-radius: 0 0 0 5px;
  z-index: 64;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .cms_gear:active ion-icon {
    -webkit-transform: translateY(2px);
    -ms-transform: translateY(2px);
    transform: translateY(2px); }
  .cms_gear:hover {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
